
import PageTeaser from '@/components/teasers/PageTeaser'
export default {
  components: {
    PageTeaser,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    pages() {
      if (this.data.pages && this.data.pages.data.length > 0) {
        const sortOrder = this.data.pages.data.map((a) => a.id)
        const filtered = this.$store.state.pages.filter((page) => {
          return this.data.pages.data.some((f) => {
            return f.id === page.id
          })
        })
        return this.mapOrder(filtered, sortOrder, 'id')
      } else {
        return this.$store.state.pages
      }
    },
  },
  methods: {
    mapOrder(array, order, key) {
      return array.sort((a, b) =>
        order.indexOf(a[key]) > order.indexOf(b[key]) ? 1 : -1
      )
    },
  },
}


import Media from '@/components/Media'
import Animation from '@/components/Animation'
import Btn from '@/components/Btn'
export default {
  components: {
    Media,
    Animation,
    Btn,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
}
